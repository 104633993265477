import { memo } from 'react';
import styled from 'styled-components';

const Paragraph = styled.p`
  border-bottom: solid 1px var(--theme-color-grey1);
  color: var(--theme-color-grey3);
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.lg} {
    font-size: 1.6rem;
    max-width: ${(props) => props.theme.maxHeaderWidth};
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
`;

type StraplineProps = {
  text: string;
};

const Strapline = ({ text }: StraplineProps) => <Paragraph>{text}</Paragraph>;

export default memo(Strapline);
